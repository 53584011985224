// components
import Seo from 'src/components/Seo';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={'Terms of Use - Seedium'}
      description={
        'Learn more about the conditions of website use,  intellectual property rights, as well as Seedium limitation of liability.'
      }
      schema={{
        schemaContext: 'https://schema.org',
        schemaType: 'WebPage',
        schemaName: 'Terms of Use - Seedium',
        schemaDescription:
          'Learn more about the conditions of website use,  intellectual property rights, as well as Seedium limitation of liability.',
        schemaUrl: 'https://seedium.io/terms',
        schemaLogo: 'https://seedium.io/images/logo.png',
      }}
    />
  );
};

export { default } from './Terms';
