import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// sections
import Content from './sections/Content';
import { PageProps } from 'gatsby';

const Terms: FC<PageProps> = () => {
  return (
    <PageLayout>
      <Intro
        title='Terms of use'
        description='This page contains conditions of Seedium website use, defines intellectual property rights, external links policy and limitation of liability.'
        icon='termsDrawing'
      />
      <Content />
    </PageLayout>
  );
};

export default Terms;
